body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

.amap-markers .amap-marker .amap-icon {
  display: none;
}

#aippt-iframe-modal {
  top: 60px !important;
}

.button-container[data-v-fbaa4b64] {
  background: #fff0f9;
  border-radius: 99px;
  box-shadow: 0 3px 24px 1px #66418b66;
  cursor: pointer;
  height: 86px;
  margin-top: 40px;
  overflow: hidden;
  padding: 8px;
  width: 333px
}

.button-container .button[data-v-fbaa4b64] {
  background: linear-gradient(138deg, #3b2af9, #562cf7 22%, #dd34ee 89%, #f5e17d);
  border-radius: 99px;
  box-shadow: 0 0 4px 1px #9056ca66;
  color: #fff;
  font-size: 26px;
  height: 100%;
  transition: all .3s cubic-bezier(.075, .82, .165, 1);
  width: 100%;
  border: 0;
  cursor: pointer;
}

.button-container .button[data-v-fbaa4b64]:hover {
  color: #ffffffb3
}

.ant-upload-drag {
  background: none !important;
  border: 0 !important;
}

.ant-spin-dot-item {
  background-color: white !important;
}
