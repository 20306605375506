.container {
  width: 1200px;
  //height: 600px;
  position: relative;
}

.img {
  //width: 1200px;
  //height: 600px !important;
}

.demo {
  width: 100vw;
  display: flex;
  justify-content: center;
}

.logo {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 720px;
  justify-content: center;
  gap: 40px;

  .logoImg {
    width: 600px;
  }
  .brand {
    font-size: 40px;
  }
  .name {
    font-size: 80px;
  }
}