.container {
  width: 100vw;
  height: 100vh;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 24px;
}

.l {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 8px;
}